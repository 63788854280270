import { BigNumber } from 'bignumber.js';
import { IEnrichedUniswapPosition } from '../../../../types/uniswapTokens';
import { Field } from '../../../../components-yldr/LiquidityChartRangeInput/types';

export const calculateValue = (enrichedPosition: IEnrichedUniswapPosition, value: string, field: Field) => {
  const ratio = BigNumber(enrichedPosition.token0.marketReferenceCurrencyValue)
    .div(enrichedPosition.token1.marketReferenceCurrencyValue);

  if (field === Field.CURRENCY_A && ratio.isFinite() && value) {
    if (enrichedPosition.poolPrice.isLessThanOrEqualTo(enrichedPosition.lowerPosition)) {
      return '0';
    }

    return BigNumber(value)
      .multipliedBy(enrichedPosition.token1.priceInMarketReferenceCurrency)
      .multipliedBy(ratio)
      .div(enrichedPosition.token0.priceInMarketReferenceCurrency)
      .decimalPlaces(12)
      .toFixed();
  }

  if (field === Field.CURRENCY_B && ratio.isFinite() && value) {
    if (enrichedPosition.poolPrice.isGreaterThanOrEqualTo(enrichedPosition.upperPosition)) {
      return '0';
    }

    return BigNumber(value)
      .multipliedBy(enrichedPosition.token0.priceInMarketReferenceCurrency)
      .div(ratio)
      .div(enrichedPosition.token1.priceInMarketReferenceCurrency)
      .decimalPlaces(12)
      .toFixed()
  }
};
