import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { BigNumber as EthBigNumber } from 'ethers';
import { ApproveType } from '@yldr/contract-helpers';
import { Box, BoxProps } from '@mui/material';
import { Trans } from '@lingui/macro';
import { TransactionResponse } from '@ethersproject/providers';
import { queryClient } from '../../../../pages/_app.page';
import { useModalContext } from '../../../hooks/useModal';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { useRootStore } from '../../../store/root';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { QueryKeys } from '../../../ui-config/queries';
import { TokensApproval } from '../../../components-yldr/components/TokensApproval';
import { ComputedReserveData } from '../../../hooks/app-data-provider/useAppDataProvider';
import { useTransactionDeadline } from '../../../components-yldr/LiquidityChartRangeInput/hooks/useTransactionDeadline';
import { IEnrichedUniswapPosition } from '../../../types/uniswapTokens';
import { TxPositionActionsWrapper } from '../TxPositionActionsWrapper';
import { MAX_SLIPPAGE } from './constants';

export interface DeleveragePositionActionsProps extends BoxProps {
  enrichedPosition: IEnrichedUniswapPosition;
  tokenA?: ComputedReserveData;
  tokenB?: ComputedReserveData;
  amountToIncreaseA: BigNumber;
  amountToIncreaseB: BigNumber;
  isWrongNetwork: boolean;
}

export const AddLiquidityActions = React.memo(
  ({ enrichedPosition, isWrongNetwork, tokenA, tokenB, amountToIncreaseA, amountToIncreaseB }: DeleveragePositionActionsProps) => {
    const [approvedAmountA, setApprovedAmountA] = useState<ApproveType | undefined>();
    const [approvedAmountB, setApprovedAmountB] = useState<ApproveType | undefined>();
    const [requiresApprovalA, setRequiresApprovalA] = useState<boolean>(false);
    const [requiresApprovalB, setRequiresApprovalB] = useState<boolean>(false);
    const { mainTxState, setMainTxState, setTxError, setGasLimit } = useModalContext();
    const { sendTx } = useWeb3Context();
    const deadline = useTransactionDeadline();

    const [
      increaseLiquidity,
      estimateGasLimit
    ] = useRootStore((state) => [
      state.increaseLiquidity,
      state.estimateGasLimit
    ]);

    const amount0Desired = amountToIncreaseA;
    const amount1Desired = amountToIncreaseB;
    const amount0Min = amount0Desired.multipliedBy(1 - MAX_SLIPPAGE / 10000);
    const amount1Min = amount1Desired.multipliedBy(1 - MAX_SLIPPAGE / 10000);

    const buttonDisabled = (amount1Desired.isZero() && amount0Desired.isZero()) || requiresApprovalA || requiresApprovalB;

    const action = async () => {
      if (
        !enrichedPosition.marketId ||
        !deadline ||
        (amount1Desired.isZero() && amount0Desired.isZero()) ||
        (amount1Min.isZero() && amount0Min.isZero()) ||
        requiresApprovalA ||
        requiresApprovalB ||
        mainTxState.success
      ) return;

      try {
        window.gtag('event', 'leverage_modal_add_liquidity');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true });

        const txData = {
          tokenId: EthBigNumber.from(enrichedPosition.tokenId),
          amount0Desired: EthBigNumber.from(amount0Desired.toFixed(0)),
          amount1Desired: EthBigNumber.from(amount1Desired.toFixed(0)),
          amount0Min: EthBigNumber.from(amount0Min.toFixed(0)),
          amount1Min: EthBigNumber.from(amount1Min.toFixed(0)),
          deadline,
        };

        const increaseLiquidityTx = increaseLiquidity(enrichedPosition.marketId, txData);
        const increaseLiquidityTxData = await estimateGasLimit(increaseLiquidityTx);

        const response: TransactionResponse = await sendTx(increaseLiquidityTxData);
        await response.wait(1);

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

        queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS_DATA] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.LEVERAGED_POSITIONS] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.LEVERAGED_POSITIONS_DATA] });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    // Update gas estimation
    useEffect(() => {
      const calculateGasLimit = async () => {
        if (
          !enrichedPosition.marketId ||
          !deadline ||
          (amount1Desired.isZero() && amount0Desired.isZero()) ||
          (amount1Min.isZero() && amount0Min.isZero()) ||
          requiresApprovalA ||
          requiresApprovalB ||
          mainTxState.success
        ) return;

        const txData = {
          tokenId: EthBigNumber.from(enrichedPosition.tokenId),
          amount0Desired: EthBigNumber.from(amount0Desired.toFixed(0)),
          amount1Desired: EthBigNumber.from(amount1Desired.toFixed(0)),
          amount0Min: EthBigNumber.from(amount0Min.toFixed(0)),
          amount1Min: EthBigNumber.from(amount1Min.toFixed(0)),
          deadline,
        };

        const increaseLiquidityTx = increaseLiquidity(enrichedPosition.marketId, txData);
        const increaseLiquidityTxData = await estimateGasLimit(increaseLiquidityTx);
        setGasLimit(increaseLiquidityTxData.gasLimit?.toString() || '0');
      }

      const timerId = setTimeout(calculateGasLimit, 500);
      const intervalId = setInterval(calculateGasLimit, 15000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timerId);
      }
    },
      [
        mainTxState.success,
        amount0Desired.toFixed(0),
        amount1Desired.toFixed(0),
        amount0Min.toFixed(0),
        amount1Min.toFixed(0),
        requiresApprovalA,
        requiresApprovalB,
        deadline,
      ]
    );

    return (
      <Box sx={{ mt: 4 }}>
        <TokensApproval
          marketId={enrichedPosition.marketId}
          tokenA={tokenA}
          tokenB={tokenB}
          amountToSupplyA={amountToIncreaseA}
          amountToSupplyB={amountToIncreaseB}
          approvedAmountA={approvedAmountA}
          approvedAmountB={approvedAmountB}
          setApprovedAmountA={setApprovedAmountA}
          setApprovedAmountB={setApprovedAmountB}
          requiresApprovalA={requiresApprovalA}
          requiresApprovalB={requiresApprovalB}
          setRequiresApprovalA={setRequiresApprovalA}
          setRequiresApprovalB={setRequiresApprovalB}
          isIncreaseLiquidity={true}
        />
        <TxPositionActionsWrapper
          mainTxState={mainTxState}
          isWrongNetwork={isWrongNetwork}
          requiresAmount
          preparingTransactions={false}
          actionText={<Trans>Add liquidity</Trans>}
          actionInProgressText={<Trans>Adding liquidity</Trans>}
          handleAction={action}
          disabled={buttonDisabled}
        />
      </Box>
    );
  }
);
