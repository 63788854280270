import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { MulticallUpdater } from '../../../components-yldr/LiquidityChartRangeInput/state/multicall';
import { ScrollModal } from '../../../components/primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { AddLiquidityModalContent } from './AddLiquidityModalContent';

export const AddLiquidityModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    positionAddress: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.AddLiquidity} setOpen={close}>
      <PositionModalWrapper
        positionAddress={args.positionAddress}
        title="Add liquidity"
        modernStyle={true}
      >
        {(params) => <AddLiquidityModalContent {...params} />}
      </PositionModalWrapper>
      <MulticallUpdater />
    </ScrollModal>
  );
};
