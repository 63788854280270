import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import useCurrentBlockTimestamp from './useCurrentBlockTimestamp';

const USER_DEADLINE = 30000;

export const useTransactionDeadline = (): BigNumber | undefined => {
  const { chainId } = useWeb3React();
  const blockTimestamp = useCurrentBlockTimestamp();
  return useMemo(() => {
    if (blockTimestamp) return blockTimestamp.add(USER_DEADLINE);
    return undefined;
  }, [blockTimestamp, chainId]);
}
